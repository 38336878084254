// prefer default export if available
var preferDefault = function preferDefault(m) {
  return m && m.default || m;
};

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": function componentNodeModulesGatsbyPluginOfflineAppShellJs() {
    return import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js"
    /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */
    );
  },
  "component---src-templates-page-js": function componentSrcTemplatesPageJs() {
    return import("/opt/build/repo/src/templates/page.js"
    /* webpackChunkName: "component---src-templates-page-js" */
    );
  },
  "component---src-templates-blog-js": function componentSrcTemplatesBlogJs() {
    return import("/opt/build/repo/src/templates/blog.js"
    /* webpackChunkName: "component---src-templates-blog-js" */
    );
  },
  "component---src-pages-404-js": function componentSrcPages404Js() {
    return import("/opt/build/repo/src/pages/404.js"
    /* webpackChunkName: "component---src-pages-404-js" */
    );
  },
  "component---src-pages-blog-js": function componentSrcPagesBlogJs() {
    return import("/opt/build/repo/src/pages/blog.js"
    /* webpackChunkName: "component---src-pages-blog-js" */
    );
  },
  "component---src-pages-elements-js": function componentSrcPagesElementsJs() {
    return import("/opt/build/repo/src/pages/elements.js"
    /* webpackChunkName: "component---src-pages-elements-js" */
    );
  },
  "component---src-pages-generic-js": function componentSrcPagesGenericJs() {
    return import("/opt/build/repo/src/pages/generic.js"
    /* webpackChunkName: "component---src-pages-generic-js" */
    );
  },
  "component---src-pages-index-js": function componentSrcPagesIndexJs() {
    return import("/opt/build/repo/src/pages/index.js"
    /* webpackChunkName: "component---src-pages-index-js" */
    );
  },
  "component---src-pages-landing-js": function componentSrcPagesLandingJs() {
    return import("/opt/build/repo/src/pages/landing.js"
    /* webpackChunkName: "component---src-pages-landing-js" */
    );
  }
};